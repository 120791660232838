import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var baseColors = {
    failure: "#FC3D21",
    primary: "#0B3D91",
    primaryBright: "#A176F3",
    primaryDark: "#0098A1",
    secondary: "#5E5C71",
    success: "#87B43A",
    warning: "#FFBB6A"
};
export var additionalColors = {
    binance: "#F0B90B",
    overlay: "#452a7a",
    gold: "#FFC700",
    silver: "#B2B2B2",
    bronze: "#E7974D"
};
export var lightColors = _object_spread_props(_object_spread({}, baseColors, additionalColors), {
    background: "#FAF9FA",
    backgroundDisabled: "#E9EAEB",
    backgroundAlt: "#FFFFFF",
    backgroundtoggle: "FF624B",
    backgroundAlt2: "rgba(255, 255, 255, 0.7)",
    cardBorder: "#FFFFFF",
    contrast: "#191326",
    dropdown: "#F6F6F6",
    dropdownDeep: "#EEEEEE",
    invertedContrast: "#FFFFFF",
    input: "rgba(230, 229, 237, 0.5);",
    inputSecondary: "#7D9CD1",
    tertiary: "#EFF4F5",
    text: "#280D5F",
    textDisabled: "#BDC2C4",
    textSubtle: "#2D2B38",
    disabled: "#E9EAEB",
    gradients: {
        bubblegum: "#093174",
        inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
        cardHeader: "linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)",
        blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
        violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
        violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
export var darkColors = _object_spread_props(_object_spread({}, baseColors, additionalColors), {
    secondary: "#E6E5ED",
    background: "#04142E",
    backgroundDisabled: "#3c3742",
    backgroundAlt: "#04142E",
    backgroundtoggle: "2D2B38",
    backgroundAlt2: "rgba(39, 38, 44, 0.7)",
    cardBorder: "rgba(255, 255, 255, 0.1)",
    contrast: "#FFFFFF",
    dropdown: "#2D2B38",
    dropdownDeep: "rgba(255, 255, 255, 0.1)",
    invertedContrast: "#191326",
    input: "rgba(255, 255, 255, 0.05)",
    inputSecondary: "#262130",
    primaryDark: "#0098A1",
    tertiary: "#2D2B38",
    text: "#F4EEFF",
    textDisabled: "#666171",
    textSubtle: " linear-gradient(201.72deg, #EAFFD1 15.29%, #EAEBEF 46.6%, #FDFDF9 73.04%)",
    disabled: "#524B63",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)",
        cardHeader: "transparent",
        blue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
        violet: "linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)",
        violetAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
