import {
  MenuItemsType,
  DropdownMenuItemType,
  DocsIcon,
  BlogIcon,
  SwapIcon,
  EarnIcon,
  NftIcon,
  NftFillIcon,
  InfoIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const filterItemBySupportChainId = (item, chainId) => {
  return !chainId || !item.supportChainIds ? true : item.supportChainIds?.includes(chainId)
}

const config: (t: ContextApi['t'], languageCode?: string, chainId?: number) => ConfigMenuItemsType[] = (
  t,
  languageCode,
  chainId,
) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapIcon,
      href: '/swap',
      showItemsOnMobile: false,
      supportChainIds: [56, 97, 1029],
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
      ],
    },
    {
      label: t('Earn'),
      href: '#',
      icon: EarnIcon,
      fillIcon: EarnIcon,
      supportChainIds: [56, 97, 1029],
      items: [
        {
          label: t('Farms'),
          href: '/farms',
        },
        {
          label: t('Pools'),
          href: '/pools',
        },
      ],
    },
    {
      label: t('Blog'),
      href: '#',
      icon: BlogIcon,
      type: DropdownMenuItemType.EXTERNAL_LINK,
      supportChainIds: [56, 97, 1029],
    },
    {
      label: t('Docs'),
      href: '#',
      icon: DocsIcon,
      type: DropdownMenuItemType.EXTERNAL_LINK,
      supportChainIds: [56, 97, 1029],
    },

    {
      label: t('NFT'),
      href: '/nfts',
      icon: NftIcon,
      fillIcon: NftFillIcon,
      supportChainIds: [56, 97, 1029],
      items: [
        {
          label: t('Overview'),
          href: '/nfts',
        },
        {
          label: t('Collections'),
          href: '/nfts/collections',
        },
        {
          label: t('Activity'),
          href: '/nfts/activity',
        },
      ],
    },
    {
      label: t('Info'),
      href: 'info',
      icon: InfoIcon,
      type: DropdownMenuItemType.EXTERNAL_LINK,
      supportChainIds: [56, 97, 1029],
    },
  ].filter((item) => filterItemBySupportChainId(item, chainId))

export default config
