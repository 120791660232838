import { ChainId } from '@moonbiz/sdk'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
// import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */

  {
    pid: 0,
    v1pid: 251,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0xA104Dafde23CB7D40e5af89Ef39B74713e2d566C',
      56: '0xA104Dafde23CB7D40e5af89Ef39B74713e2d566C',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    v1pid: 252,
    lpSymbol: 'RP1-TUSD OLD LP',
    lpAddresses: {
      97: '0x7009F88bcf38490D628594F4E7582C0478051eC8',
      56: '0x7009F88bcf38490D628594F4E7582C0478051eC8',
    },
    token: serializedTokens.tusdold,
    quoteToken: serializedTokens.rp1,
  },
  {
    pid: 2,
    v1pid: 253,
    lpSymbol: 'BNB-TUSDOLD LP',
    lpAddresses: {
      97: '0x22fE41B8CF9b2E82057E5bb77b3b01B9DC0FDAaF',
      56: '0x22fE41B8CF9b2E82057E5bb77b3b01B9DC0FDAaF',
    },
    token: serializedTokens.tusdold,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 3,
    v1pid: 254,
    lpSymbol: 'RP1-BNB LP',
    lpAddresses: {
      97: '0x83e925f02e84d6baBd206CEDAC5b6Db38279d18A',
      56: '0x83e925f02e84d6baBd206CEDAC5b6Db38279d18A',
    },
    token: serializedTokens.wbnb,
    quoteToken: serializedTokens.rp1,
  },
  {
    pid: 4,
    v1pid: 255,
    lpSymbol: 'TUSD-RP1 LP',
    lpAddresses: {
      97: '0xA09eB5dc0c77a68093d7D39f4B72343CB97f286D',
      56: '0xA09eB5dc0c77a68093d7D39f4B72343CB97f286D',
    },
    token: serializedTokens.tusd,
    quoteToken: serializedTokens.rp1,
  },
  {
    pid: 5,
    v1pid: 256,
    lpSymbol: 'BNB-TUSD LP',
    lpAddresses: {
      97: '0x1c0481b95901F93393c32c1BFDf6618D37d73A13',
      56: '0x1c0481b95901F93393c32c1BFDf6618D37d73A13',
    },
    token: serializedTokens.wbnb,
    quoteToken: serializedTokens.tusd,
  },
].filter((f) => !!f.lpAddresses[ChainId.TESTNET])

export default farms
