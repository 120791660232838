import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  border: 1px solid #d6d6e8;\n  border-radius: 10px;\n  display: block;\n  font-size: 16px;\n  height: ",
        ";\n  outline: 0;\n  padding: 0 16px;\n  width: 100%;\n  margin-bottom: 20px;\n\n  /* &:disabled {\n    background-color: ",
        ";\n    box-shadow: none;\n    color: ",
        ";\n    cursor: not-allowed;\n  }\n\n  &:focus:not(:disabled) {\n    box-shadow: ",
        ";\n  } */\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { scales } from "./types";
/**
 * Priority: Warning --> Success
 */ // const getBoxShadow = ({ isSuccess = false, isWarning = false, theme }: StyledInputProps) => {
//   if (isWarning) {
//     return theme.shadows.warning;
//   }
//   if (isSuccess) {
//     return theme.shadows.success;
//   }
//   return theme.shadows.inset;
// };
var getHeight = function(param) {
    var _scale = param.scale, scale = _scale === void 0 ? scales.MD : _scale;
    switch(scale){
        case scales.SM:
            return "32px";
        case scales.LG:
            return "48px";
        case scales.MD:
        default:
            return "40px";
    }
};
var SattInput = styled.input.withConfig({
    componentId: "sc-17b05aa7-0"
})(_templateObject(), function(param) {
    var theme = param.theme;
    return theme.colors.input;
}, getHeight, function(param) {
    var theme = param.theme;
    return theme.colors.backgroundDisabled;
}, function(param) {
    var theme = param.theme;
    return theme.colors.textDisabled;
}, function(param) {
    var theme = param.theme;
    return theme.shadows.focus;
});
SattInput.defaultProps = {
    scale: scales.MD,
    isSuccess: false,
    isWarning: false
};
export default SattInput;
