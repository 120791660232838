import { TokenAmount, Pair, PairPancake, Currency, Token } from '@moonbiz/sdk'

import { useEffect, useMemo } from 'react'
import IPancakePairABI from 'config/abi/IPancakePair.json'
import { Interface } from '@ethersproject/abi'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getCreate2Address } from '@ethersproject/address'
import { pack, keccak256 } from '@ethersproject/solidity'
import { ethers } from 'ethers'
import { useMultipleContractSingleData, useMultipleContractSingleDataNFT } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'

const PAIR_INTERFACE = new Interface(IPancakePairABI)
export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

const composeKey = (token0: Token, token1: Token) => `${token0.chainId}-${token0.address}-${token1.address}`

let PAIR_ADDRESS_CACHE: { [key: string]: string } = {}
let PAIR_ADDRESS_CACHE_CAKE: { [key: string]: string } = {}

const checkContractCode = (address: string): Promise<boolean> => {
  const web3 = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/')

  return new Promise((resolve, reject) => {
    web3
      .getCode(address)
      .then((bytecode) => {
        resolve(bytecode !== '0x')
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function usePairs(currencies: [any | undefined, any | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(() => {
    if (currencies && Array.isArray(currencies) && currencies.length > 0) {
      const [currencyA, currencyB] = currencies[0]
      return [[wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]]
    }
    return [] // Return a default value if currencies is not an array or is empty
  }, [chainId, currencies])

  useEffect(() => {
    if (tokens.length > 0 && tokens[0][0] && tokens[0][1]) {
      getAddress(tokens[0][0], tokens[0][1])
    }
  }, [tokens])

  const address = JSON.parse(localStorage.getItem('PAIR_ADDRESS_CACHE'))
  const results = useMultipleContractSingleData(
    tokens && tokens[0] && tokens[0][0] && tokens[0][1] && !tokens[0][0].equals(tokens[0][1]) ? [address] : undefined,
    PAIR_INTERFACE,
    'getReserves',
  )

  return useMemo(() => {
    try {
      const result = results[0]
      if (!result) return [[PairState.LOADING, null]]
      const { result: reserves, loading } = result
      const tokenA = tokens[0][0]
      const tokenB = tokens[0][1]

      if (loading) return [[PairState.LOADING, null]]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [[PairState.INVALID, null]]
      if (!reserves) return [[PairState.NOT_EXISTS, null]]

      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

      return [
        [
          PairState.EXISTS,
          new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString())),
        ],
      ]
    } catch (err) {
      return [[PairState.LOADING, null]]
    }
  }, [results, tokens])
}

export function usePairsPancake(currencies: [any | undefined, any | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(() => {
    if (currencies && Array.isArray(currencies) && currencies.length > 0) {
      const [currencyA, currencyB] = currencies[0]
      return [[wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]]
    }
    return [] // Return a default value if currencies is not an array or is empty
  }, [chainId, currencies])

  useEffect(() => {
    if (tokens.length > 0 && tokens[0][0] && tokens[0][1]) {
      getAddressPancake(tokens[0][0], tokens[0][1])
    }
  }, [tokens])

  const address = JSON.parse(localStorage.getItem('PAIR_ADDRESS_CACHE'))
  const results = useMultipleContractSingleData(
    tokens && tokens[0] && tokens[0][0] && tokens[0][1] && !tokens[0][0].equals(tokens[0][1]) ? [address] : undefined,
    PAIR_INTERFACE,
    'getReserves',
  )

  return useMemo(() => {
    try {
      const result = results[0]
      if (!result) return [[PairState.LOADING, null]]
      const { result: reserves, loading } = result
      const tokenA = tokens[0][0]
      const tokenB = tokens[0][1]

      if (loading) return [[PairState.LOADING, null]]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [[PairState.INVALID, null]]
      if (!reserves) return [[PairState.NOT_EXISTS, null]]

      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

      return [
        [
          PairState.EXISTS,
          new PairPancake(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString())),
        ],
      ]
    } catch (err) {
      return [[PairState.LOADING, null]]
    }
  }, [results, tokens])
}

export function usePairsNFT(currencies: [any | undefined, any | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()
  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies],
  )

  const pairAddresses = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return, consistent-return
      tokens.map(([tokenA, tokenB]) => {
        try {
          return tokenA && tokenB && !tokenA.equals(tokenB) ? getAddress(tokenA, tokenB) : undefined
        } catch (error: any) {
          return undefined
        }
      }),
    [tokens],
  )

  const results = useMultipleContractSingleDataNFT(pairAddresses, PAIR_INTERFACE, 'getReserves')
  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]
      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString())),
      ]
    })
  }, [results, tokens])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]])[0]
}

export function getAddress(tokenA: Token, tokenB: Token): string {
  try {
    if (tokenA && tokenB) {
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks
      const key = composeKey(token0, token1)

      const pairAddress = getCreate2Address(
        '0x9B2593839E1390ECee3B348a47B3D93b2Ec2834C',
        keccak256(['bytes'], [pack(['address', 'address'], [token0.address, token1.address])]),
        '0x2a7d289ea93ade5b769c0f3194bffbd74bc155874f0679be29ce150acbf8fc67',
      )

      PAIR_ADDRESS_CACHE = {
        ...PAIR_ADDRESS_CACHE,
        [key]: pairAddress,
      }

      const pairAddresspancake = getCreate2Address(
        '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
        keccak256(['bytes'], [pack(['address', 'address'], [token0.address, token1.address])]),
        '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
      )

      PAIR_ADDRESS_CACHE_CAKE = {
        ...PAIR_ADDRESS_CACHE_CAKE,
        [key]: pairAddresspancake,
      }

      PAIR_ADDRESS_CACHE = {
        ...PAIR_ADDRESS_CACHE,
        [key]: pairAddress,
      }

      /* eslint-disable */
      if (typeof localStorage !== 'undefined') {
        checkContractCode(pairAddress)
          .then((result) => {
            if (result === true) {
              localStorage.setItem('PAIR_ADDRESS_CACHE', JSON.stringify(pairAddress))
              localStorage.setItem('FACTORYTYPE', JSON.stringify('MoonBiz'))
            } else {
              checkContractCode(pairAddresspancake)
                .then((result) => {
                  if (result === true) {
                    localStorage.setItem('PAIR_ADDRESS_CACHE', JSON.stringify(pairAddresspancake))
                    localStorage.setItem('FACTORYTYPE', JSON.stringify('Pancake'))
                  }
                })
                .catch((error) => {
                  console.error(error)
                })
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
      /* eslint-enable */

      if (typeof localStorage !== 'undefined') {
        return JSON.parse(localStorage.getItem('PAIR_ADDRESS_CACHE'))
      }
    }
    return null
  } catch (err) {
    console.log({ err })
    return null
  }
}

export function getAddressPancake(tokenA: Token, tokenB: Token): string {
  try {
    if (tokenA && tokenB) {
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks
      const key = composeKey(token0, token1)

      const pairAddresspancake = getCreate2Address(
        '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
        keccak256(['bytes'], [pack(['address', 'address'], [token0.address, token1.address])]),
        '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
      )

      PAIR_ADDRESS_CACHE = {
        ...PAIR_ADDRESS_CACHE,
        [key]: pairAddresspancake,
      }

      /* eslint-disable */
      if (typeof localStorage !== 'undefined') {
        checkContractCode(pairAddresspancake)
          .then((result) => {
            if (result === true) {
              localStorage.setItem('PAIR_ADDRESS_CACHE', JSON.stringify(pairAddresspancake))
              localStorage.setItem('FACTORYTYPE', JSON.stringify('Pancake'))
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
      /* eslint-enable */

      if (typeof localStorage !== 'undefined') {
        return JSON.parse(localStorage.getItem('PAIR_ADDRESS_CACHE'))
      }
    }
    return null
  } catch (err) {
    console.log({ err })
    return null
  }
}
