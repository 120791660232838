import { useState } from 'react'
import { escapeRegExp } from 'utils'
import { Text, Button, Flex, Box, InputProps } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { useUserSlippageTolerance, useUserTransactionTTL } from 'state/user/hooks'
import styled, { useTheme } from 'styled-components'
import QuestionHelper from '../../QuestionHelper'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

const ButtonSettings = styled.div`
  background: #ffffff;
  border: 1px solid #c9dcfb;
  border-radius: 20px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  align-items: center;
  color: #7d9cd1;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  width: 48px;
  margin: 0 10px;
  padding: 7px;
`
export const Input = styled.input<{ $isDark: boolean }>`
  background: #ffffff;
  border-radius: 20px;
  color: ${({ $isDark }) => ($isDark ? 'ffffff' : '#7d9cd1')};
  background: ${({ $isDark }) => ($isDark ? 'rgba(255, 255, 255, 0.1)' : '#ffffff')};
  border: ${({ $isDark }) => ($isDark ? '0' : '1px solid #c9dcfb')};
  padding: 7px;
  width: 62px;
  justify-content: center;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  -webkit-box-pack: center;
  align-items: center;
  -webkit-box-align: center;
  letter-spacing: 0.03em;
  line-height: 16px;
`

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

const SlippageTabs = () => {
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()
  const [ttl, setTtl] = useUserTransactionTTL()
  const [slippageInput, setSlippageInput] = useState('')
  const [deadlineInput, setDeadlineInput] = useState('')

  const { t } = useTranslation()
  const theme = useTheme()
  const slippageInputIsValid =
    slippageInput === '' || (userSlippageTolerance / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  const deadlineInputIsValid = deadlineInput === '' || (ttl / 60).toString() === deadlineInput

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && userSlippageTolerance < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && userSlippageTolerance > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  let deadlineError: DeadlineError | undefined
  if (deadlineInput !== '' && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput
  } else {
    deadlineError = undefined
  }
  const parseCustomSlippage = (value: string) => {
    if (value === '' || inputRegex.test(escapeRegExp(value))) {
      setSlippageInput(value)

      try {
        const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
        if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
          setUserSlippageTolerance(valueAsIntFromRoundedFloat)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const parseCustomDeadline = (value: string) => {
    setDeadlineInput(value)

    try {
      const valueAsInt: number = Number.parseInt(value) * 60
      if (!Number.isNaN(valueAsInt) && valueAsInt > 0) {
        setTtl(valueAsInt)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" mb="24px">
        <Flex mb="12px">
          <Text>{t('Slippage Tolerance')}</Text>
          <QuestionHelper
            text={t(
              'Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.',
            )}
            placement="top-start"
            ml="4px"
          />
        </Flex>
        <Flex flexWrap="wrap">
          <ButtonSettings
            //  $router={router.pathname}
            style={{
              background:
                userSlippageTolerance === 10 && !theme.isDark
                  ? '#0B3D91'
                  : userSlippageTolerance === 10 && theme.isDark
                  ? 'rgba(11, 61, 145, 0.8)'
                  : userSlippageTolerance !== 10 && theme.isDark
                  ? 'rgba(255, 255, 255, 0.1)'
                  : '#FFFFFF',
              color:
                userSlippageTolerance === 10 || (userSlippageTolerance !== 10 && theme.isDark) ? '#FFFFFF' : '#7D9CD1',
              border: theme.isDark ? '0px' : '1px solid #c9dcfb',
            }}
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(10)
            }}
          >
            0.1%
          </ButtonSettings>
          <ButtonSettings
            style={{
              background:
                userSlippageTolerance === 50 && !theme.isDark
                  ? '#0B3D91'
                  : userSlippageTolerance === 50 && theme.isDark
                  ? 'rgba(11, 61, 145, 0.8)'
                  : userSlippageTolerance !== 50 && theme.isDark
                  ? 'rgba(255, 255, 255, 0.1)'
                  : '#FFFFFF',
              color:
                userSlippageTolerance === 50 || (userSlippageTolerance !== 50 && theme.isDark) ? '#FFFFFF' : '#7D9CD1',
              border: theme.isDark ? '0px' : '1px solid #c9dcfb',
            }}
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(50)
            }}
          >
            0.5%
          </ButtonSettings>
          <ButtonSettings
            style={{
              background:
                userSlippageTolerance === 100 && !theme.isDark
                  ? '#0B3D91'
                  : userSlippageTolerance === 100 && theme.isDark
                  ? 'rgba(11, 61, 145, 0.8)'
                  : userSlippageTolerance !== 100 && theme.isDark
                  ? 'rgba(255, 255, 255, 0.1)'
                  : '#FFFFFF',
              color:
                userSlippageTolerance === 100 || (userSlippageTolerance !== 100 && theme.isDark)
                  ? '#FFFFFF'
                  : '#7D9CD1',
              border: theme.isDark ? '0px' : '1px solid #c9dcfb',
            }}
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(100)
            }}
          >
            1.0%
          </ButtonSettings>
          <Flex alignItems="center">
            <Box>
              <Input
                style={{ margin: '0 0 0 10px' }}
                inputMode="decimal"
                placeholder={(userSlippageTolerance / 100).toFixed(2)}
                pattern="^[0-9]*[.,]?[0-9]{0,2}$"
                value={slippageInput}
                onBlur={() => {
                  parseCustomSlippage((userSlippageTolerance / 100).toFixed(2))
                }}
                onChange={(event) => {
                  if (event.currentTarget.validity.valid) {
                    parseCustomSlippage(event.target.value.replace(/,/g, '.'))
                  }
                }}
                $isDark={theme.isDark}
              />{' '}
              %
            </Box>
          </Flex>
        </Flex>
        {!!slippageError && (
          <Text fontSize="14px" color={slippageError === SlippageError.InvalidInput ? 'red' : '#F3841E'} mt="8px">
            {slippageError === SlippageError.InvalidInput
              ? t('Enter a valid slippage percentage')
              : slippageError === SlippageError.RiskyLow
              ? t('Your transaction may fail')
              : t('Your transaction may be frontrun')}
          </Text>
        )}
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb="24px">
        <Flex alignItems="center">
          <Text>{t('Tx deadline (mins)')}</Text>
          <QuestionHelper
            text={t('Your transaction will revert if it is left confirming for longer than this time.')}
            placement="top-start"
            ml="4px"
          />
        </Flex>
        <Flex>
          <Box width="52px" mt="4px">
            <Input
              pattern="^[0-9]+$"
              color={deadlineError ? 'red' : undefined}
              onBlur={() => {
                parseCustomDeadline((ttl / 60).toString())
              }}
              placeholder={(ttl / 60).toString()}
              value={deadlineInput}
              onChange={(event) => {
                if (event.currentTarget.validity.valid) {
                  parseCustomDeadline(event.target.value)
                }
              }}
              $isDark={theme.isDark}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SlippageTabs
