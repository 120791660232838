import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'state'
import { ethers } from 'ethers'
import { sattActions } from './index'

export const useSattWallet = () => {
  const dispatch = useDispatch()
  const { sattAddress, sattPassword, keyStore, isSattConnected, sattConfirmation } = useSelector(
    (state: AppState) => state.satt,
  )

  const addSattUserAddress = (sattData: any) => {
    dispatch(sattActions.sattSignIn(sattData))
  }
  const sattLogout = () => {
    dispatch(sattActions.sattLogout())
    localStorage.removeItem('sattToken')
    localStorage.removeItem('connectorIdv2')
    localStorage.removeItem('wallet')
  }

  const addSattPassword = (password: string) => {
    dispatch(sattActions.addPassword(password))
  }

  const getSattSigner = (password: string) => {
    if (!password) return null
    try {
      const provider = new ethers.providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545/')
      const wallet = ethers.Wallet.fromEncryptedJsonSync(JSON.stringify(keyStore), password)

      const sattSigner = wallet.connect(provider)

      return sattSigner
    } catch (error) {
      return null
    }
  }

  return {
    sattAddress,
    sattPassword,
    keyStore,
    addSattUserAddress,
    sattLogout,
    addSattPassword,
    getSattSigner,
    isSattConnected,
    sattConfirmation,
  }
}
