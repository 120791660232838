import BigNumber from 'bignumber.js'
import { BIG_ONE, BIG_ZERO } from 'utils/bigNumber'
import { filterFarmsByQuoteToken } from 'utils/farmsPriceHelpers'
import { SerializedFarm } from 'state/types'
import axios from 'axios'

import tokens from 'config/constants/tokens'
/* eslint-disable */
import { rp1PriceUsd } from './hooks'
let priceSatt
let priceRp1
const sattPrice = async () => {
  const prices = await axios.get('https://api-preprod2.satt-token.com/wallet/cryptoDetails')

  return prices.data.data.SATT.price
}

export const sattPriceBigNumber = async () => {
  let price: number
  const prices = await sattPrice().then((data) => {
    priceSatt = data
  })
  return price
}

export const rp1PriceBigNumber = async () => {
  // const pairAddress = getAddress(testnetTokens.rp1, testnetTokens.tusd)
  // const contract = getLpContract(pairAddress)
  // const rp1price = contract.getReserves().then((data) => {
  // const reserve1 = data._reserve1.toString()
  // const reserve0 = data._reserve0.toString()
  // const price = new BigNumber(reserve1).div(new BigNumber(reserve0))
  const price = rp1PriceUsd()
  // })
  // const prices = await rp1price.then((data) => {
  //   priceRp1 = data.toNumber()
  // })
  return price
}

const getFarmFromTokenSymbol = (
  farms: SerializedFarm[],
  tokenSymbol: string,
  preferredQuoteTokens?: string[],
): SerializedFarm => {
  const farmsWithTokenSymbol = farms.filter((farm) => {
    return farm.quoteToken.symbol === tokenSymbol && farm.pid !== 0 && farm.pid !== 1 && farm.pid !== 2
  })
  const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
  return filteredFarm
}

const getFarmBaseTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  bnbPriceBusd: BigNumber,
): BigNumber => {
  const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

  if (farm.quoteToken.symbol === tokens.busd.symbol) {
    return hasTokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (farm.quoteToken.symbol === tokens.rp1.symbol) {
    return hasTokenPriceVsQuote ? BigNumber(localStorage.getItem('price')).times(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (farm.quoteToken.symbol === tokens.wbnb.symbol) {
    return hasTokenPriceVsQuote ? bnbPriceBusd.times(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  // We can only calculate profits without a quoteTokenFarm for BUSD/BNB farms
  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  // Possible alternative farm quoteTokens:
  // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
  // If the farm's quote token isn't BUSD or WBNB, we then use the quote token, of the original farm's quote token
  // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - BNB, (pBTC - BNB)
  // from the BNB - pBTC price, we can calculate the PNT - BUSD price
  if (quoteTokenFarm.quoteToken.symbol === tokens.wbnb.symbol) {
    const quoteTokenInBusd = bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote)
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === tokens.busd.symbol) {
    const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  // Catch in case token does not have immediate or once-removed BUSD/WBNB quoteToken
  return BIG_ZERO
}

const getFarmQuoteTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  bnbPriceBusd: BigNumber,
): BigNumber => {
  if (
    farm.quoteToken.symbol === 'BUSD' ||
    farm.quoteToken.symbol === 'USDT' ||
    farm.quoteToken.symbol === 'TUSD' ||
    farm.quoteToken.symbol === 'TUSDOLD'
  ) {
    return BIG_ONE
  }
  if (farm.quoteToken.symbol === 'RP1') {
    return BigNumber(localStorage.getItem('price'))
  }

  if (farm.quoteToken.symbol === 'SATT') {
    return new BigNumber(priceSatt)
  }

  if (farm.quoteToken.symbol === 'WBNB') {
    return bnbPriceBusd
  }

  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'WBNB') {
    return quoteTokenFarm.tokenPriceVsQuote ? bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'BUSD') {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  return BIG_ZERO
}
// @ts-ignore
const getFarmsPrices = (farms: SerializedFarm[]) => {
  const bnbBusdFarm = farms.find((farm) => {
    return farm.v1pid === 253
  })
  const bnbPriceBusd = bnbBusdFarm.tokenPriceVsQuote ? BIG_ONE.div(bnbBusdFarm.tokenPriceVsQuote) : BIG_ZERO

  const farmsWithPrices = farms
    .filter((f) => f.pid !== 0 && f.pid !== 1 && f.pid !== 2)
    .map((farm) => {
      const quoteTokenFarm = getFarmFromTokenSymbol(farms, farm.quoteToken.symbol)
      const tokenPriceBusd = getFarmBaseTokenPrice(farm, quoteTokenFarm, bnbPriceBusd)
      const quoteTokenPriceBusd = getFarmQuoteTokenPrice(farm, quoteTokenFarm, bnbPriceBusd)

      return {
        ...farm,
        tokenPriceBusd: tokenPriceBusd.toJSON(),
        quoteTokenPriceBusd: quoteTokenPriceBusd.toJSON(),
      }
    })

  return farmsWithPrices
}

export default getFarmsPrices
