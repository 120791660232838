export const BSC_RPC_URLS = [
  'https://bsc-dataseed.binance.org',
  'https://bsc-dataseed.binance.org',
  'https://bsc-dataseed.binance.org',
]

export const BSC_TESTNET_RPC_URLS = [
  'https://bsc-dataseed.binance.org',
  'https://bsc-dataseed.binance.org',
  'https://bsc-dataseed.binance.org',
]

export const BTT_TESTNET_RPC_URLS = [
  'https://testrpc.bittorrentchain.io/',
  'https://testrpc.bittorrentchain.io/',
  'https://testrpc.bittorrentchain.io/',
]
