import Metamask from "../../components/Svg/Icons/Metamask";
import WalletConnect from "../../components/Svg/Icons/WalletConnect";
import TrustWallet from "../../components/Svg/Icons/TrustWallet";
import CoinbaseWallet from "../../components/Svg/Icons/CoinbaseWallet";
import { ConnectorNames } from "./types";
var connectors = [
    // {
    //   title: "SattWallet",
    //   icon: Satt,
    //   connectorId: ConnectorNames.Satt,
    //   priority: 1,
    // },
    {
        title: "Metamask",
        icon: Metamask,
        connectorId: ConnectorNames.Injected,
        priority: 1,
        href: "https://metamask.app.link/dapp/testnet.moon.biz/"
    },
    {
        title: "WalletConnect",
        icon: WalletConnect,
        connectorId: ConnectorNames.WalletConnect,
        priority: 2
    },
    // {
    //   title: "Binance Wallet",
    //   icon: BinanceChain,
    //   connectorId: ConnectorNames.BSC,
    //   priority: 3,
    // },
    {
        title: "Trust Wallet",
        icon: TrustWallet,
        connectorId: ConnectorNames.Injected,
        priority: 4,
        mobileOnly: true
    },
    {
        title: "Coinbase Wallet",
        icon: CoinbaseWallet,
        connectorId: ConnectorNames.WalletLink,
        priority: 5
    }, 
];
export default connectors;
export var connectorLocalStorageKey = "connectorIdv2";
export var walletLocalStorageKey = "wallet";
export var sattTokenLocalStorageKey = "sattToken";
