import { Contract } from '@ethersproject/contracts'
import { Signer, providers, ethers, Wallet } from 'ethers'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import IPancakeRouter02ABI from 'config/abi/IPancakeRouter02.json'

// import MultiRouter from 'config/abi/MultiRouter.json'
// import { IPancakeRouter02 } from 'config/abi/types/IPancakeRouter02'
import { CHAIN_ID } from 'config/constants/networks'
import { JSBI, Percent, Token, CurrencyAmount, Currency, ETHER } from '@moonbiz/sdk'
import { TokenAddressMap } from 'state/types'
import { ROUTER_ADDRESS, ROUTER_ADDRESS_PANCAKE } from '../config/constants'
import { BASE_BSC_SCAN_URLS } from '../config'
import { bscRpcProvider } from './providers'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getBscScanLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainIdOverride?: number,
): string {
  const chainId = chainIdOverride || CHAIN_ID
  switch (type) {
    case 'transaction': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/tx/${data}`
    }
    case 'token': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/token/${data}`
    }
    case 'block': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/block/${data}`
    }
    case 'countdown': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/block/countdown/${data}`
    }
    default: {
      return `${BASE_BSC_SCAN_URLS[chainId]}/address/${data}`
    }
  }
}

export const getSattSigner = (password?: string) => {
  const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.defibit.io/')
  const wallet = ethers.Wallet.fromEncryptedJsonSync(
    '{"crypto":{"cipherparams":{"iv":"14850680b4f4f1ed46fc1d762a0e5047"},"kdfparams":{"dklen":32,"salt":"8c7afdad0a56aebaee37d48555d259f21d867635ac98f8a8e42d79239ae11e80","n":8192,"r":8,"p":1},"ciphertext":"9d310fc6b910ca21088aaa72a07f7edc22aa5426f2af4f2c0d59118cb3cf0466","cipher":"aes-128-ctr","kdf":"scrypt","mac":"cb3146e0b3c5a2c08f3dec581e18a063844c55c0e60511c7812a15668c0672e2"},"version":3,"id":"740e55e4-c0f1-4092-86cf-3cce03fdce41","address":"faa9a21321defcf1b168ca90ad5ac8885b3402a5"}',
    password,
  )
  const sattSigner = wallet.connect(provider)

  return sattSigner
}

export function getBscScanLinkForNft(collectionAddress: string, tokenId: string): string {
  const chainId = CHAIN_ID
  return `${BASE_BSC_SCAN_URLS[chainId]}/token/${collectionAddress}?a=${tokenId}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner | Wallet {
  return account ? getSigner(library, account) : null
}

// account is optional
export function getContract(address: string, ABI: any, signer?: Signer | providers.Provider): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new ethers.Contract(address, ABI, signer ?? bscRpcProvider)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider | Wallet, account?: string) {
  if (library instanceof Web3Provider) {
    return getContract(ROUTER_ADDRESS[CHAIN_ID], IPancakeRouter02ABI, getProviderOrSigner(library, account))
  }
  return getContract(ROUTER_ADDRESS[CHAIN_ID], IPancakeRouter02ABI, library)
}

export function getRouterContractPancake(_: number, library: Web3Provider | Wallet, account?: string) {
  if (library instanceof Web3Provider) {
    return getContract(ROUTER_ADDRESS_PANCAKE[CHAIN_ID], IPancakeRouter02ABI, getProviderOrSigner(library, account))
  }
  return getContract(ROUTER_ADDRESS_PANCAKE[CHAIN_ID], IPancakeRouter02ABI, library)
}
export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}
