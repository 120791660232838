import { createSlice } from '@reduxjs/toolkit'
import { SattWalletData } from './types'

const initialState: SattWalletData = {
  isSattConnected: false,
  sattAddress: '',
  sattPassword: null,
  keyStore: null,
  sattConfirmation: false,
}

export const sattSlice = createSlice({
  name: 'Satt',
  initialState,
  reducers: {
    sattSignIn(state, action) {
      state.sattAddress = action.payload.address
      state.keyStore = action.payload.keyStore
      state.isSattConnected = true
    },
    sattLogout(state) {
      state.sattAddress = ''
      state.sattPassword = null
      state.sattConfirmation = false
      state.isSattConnected = false
    },
    addPassword(state, action) {
      state.sattPassword = action.payload
      state.sattConfirmation = true
    },
  },
})

export const sattActions = sattSlice.actions

export default sattSlice.reducer
