import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'
// import lkesitguy from '../../public/fonts/LuckiestGuy.ttf'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
  * {
    font-family:'Work Sans' !important;

  }
  h2{
    font-family:'Work Sans';
    letter-spacing: 0.03em;

  }

    span{
      font-family: 'Open Sans';
      
    }
    
    

  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
