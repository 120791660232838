import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background: ",
        ";\n  width: 100%;\n  margin-bottom: 0px;\n  padding-bottom: 0;\n  border-bottom0;\n  "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  margin-bottom: 0px;\n  width: 100% ",
        " {\n    margin-bottom: 0px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-size: 16px;\n  margin-bottom: 0px;\n  text-transform: capitalize;\n\n  &:first-child {\n    color: ",
        ";\n    font-weight: 600;\n    text-transform: uppercase;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 0px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  border-color: ",
        ";\n  border-top-width: 1px;\n  border-bottom-width: 0;\n  border-style: solid;\n  padding: 24px 1px 24px 0px;\n  margin-bottom: 0;\n  width: 100% ",
        " {\n    border-top-width: 0;\n    border-bottom-width: 0;\n    padding: 0 0;\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  border-bottom: 0;\n  grid-row-gap: 10px;\n\n  ",
        ";\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { Box, Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";
export var StyledFooter = styled(Flex).withConfig({
    componentId: "sc-a67e1bf8-0"
})(_templateObject(), function(param) {
    var theme = param.theme;
    return theme.isDark ? "rgba(6, 33, 79, 0.5)" : "#FFFFFF";
});
export var StyledList = styled.ul.withConfig({
    componentId: "sc-a67e1bf8-1"
})(_templateObject1(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.md;
});
export var StyledListItem = styled.li.withConfig({
    componentId: "sc-a67e1bf8-2"
})(_templateObject2(), function(param) {
    var theme = param.theme;
    return theme.colors.secondary;
});
export var StyledIconMobileContainer = styled(Box).withConfig({
    componentId: "sc-a67e1bf8-3"
})(_templateObject3());
export var StyledToolsContainer = styled(Flex).withConfig({
    componentId: "sc-a67e1bf8-4"
})(_templateObject4(), function(param) {
    var theme = param.theme;
    return theme.colors.cardBorder;
}, function(param) {
    var theme = param.theme;
    return theme.mediaQueries.sm;
});
export var StyledSocialLinks = styled(SocialLinks).withConfig({
    componentId: "sc-a67e1bf8-5"
})(_templateObject5(), function(param) {
    var theme = param.theme;
    return theme.colors.cardBorder;
});
export var StyledText = styled.li.withConfig({
    componentId: "sc-a67e1bf8-6"
})(_templateObject6(), function(param) {
    var theme = param.theme;
    return theme.colors.text;
});
