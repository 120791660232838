import { useEffect, useState } from 'react'

export const BAD_SRCS: { [imageSrc: string]: true } = {}

export interface LogoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  srcs: string[]
}

const DEFAULT_IMAGE = '/images/errors.jpg' // Remplacez par l'URL de votre image par défaut

/**
 * Renders an image by sequentially trying a list of URIs, and then eventually a fallback triangle alert
 */
const Logo: React.FC<LogoProps> = ({ srcs, alt, ...rest }) => {
  const [currentSrc, setCurrentSrc] = useState(srcs[0] || DEFAULT_IMAGE)

  const handleError = () => {
    const nextSrc = srcs.find((src) => src !== currentSrc && !BAD_SRCS[src])

    if (nextSrc) {
      setCurrentSrc(nextSrc)
    } else if (currentSrc !== DEFAULT_IMAGE) {
      setCurrentSrc(DEFAULT_IMAGE)
    }
  }

  useEffect(() => {
    setCurrentSrc(srcs[0] || DEFAULT_IMAGE)
  }, [srcs])
  return <img {...rest} alt={alt} src={currentSrc} onError={handleError} />
}

export default Logo
