import _define_property from "@swc/helpers/src/_define_property.mjs";
import { scales, variants } from "./types";
var _obj;
export var scaleVariants = (_obj = {}, _define_property(_obj, scales.MD, {
    height: "48px",
    padding: "0 24px"
}), _define_property(_obj, scales.SM, {
    height: "32px",
    padding: "0 16px"
}), _define_property(_obj, scales.XS, {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px"
}), _obj);
var _obj1;
export var styleVariants = (_obj1 = {}, _define_property(_obj1, variants.PRIMARY, {
    backgroundColor: "primary",
    color: "white"
}), _define_property(_obj1, variants.PRIMARYDARK, {
    backgroundColor: "input",
    color: "primary",
    borderColor: "primary",
    border: "1px solid #0B3D91"
}), _define_property(_obj1, variants.PRIMARYSETTINGS, {
    backgroundColor: "rgba(11, 61, 145, 0.8)",
    color: "#FFFFFF",
    border: "1px solid #7D9CD1",
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)"
}), _define_property(_obj1, variants.SECONDARY, {
    backgroundColor: "#FFFFFF",
    border: "2px solid",
    borderColor: "secondary",
    boxShadow: "none",
    color: "secondary",
    ":disabled": {
        backgroundColor: "transparent"
    }
}), _define_property(_obj1, variants.TERTIARY, {
    backgroundColor: "tertiary",
    boxShadow: "none",
    color: "primary"
}), _define_property(_obj1, variants.TERTIARYSETTINGS, {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
    color: "#D6DEEB"
}), _define_property(_obj1, variants.SUBTLE, {
    backgroundColor: "textSubtle",
    color: "backgroundAlt"
}), _define_property(_obj1, variants.DANGER, {
    backgroundColor: "failure",
    color: "white",
    border: "1px solid #931300"
}), _define_property(_obj1, variants.SUCCESS, {
    backgroundColor: "primary",
    color: "white"
}), _define_property(_obj1, variants.PRIMARYMYWALLET, {
    backgroundColor: "#7D9CD1",
    color: "white"
}), _define_property(_obj1, variants.TEXT, {
    backgroundColor: "transparent",
    color: "#7D9CD1",
    boxShadow: "none"
}), _define_property(_obj1, variants.LIGHT, {
    backgroundColor: "input",
    color: "textSubtle",
    boxShadow: "none",
    border: "2px solid #FFFFFF"
}), _obj1);
