import { Flex, Button, Text, BaseButtonProps, ButtonProps } from '@pancakeswap/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { useTranslation } from 'contexts/Localization'
import { useGasPriceManager } from 'state/user/hooks'
import { GAS_PRICE_GWEI, GAS_PRICE } from 'state/types'
import styled from 'styled-components'

const StyledButton = styled.button<ButtonProps>`
  margin-top: 4px;
  margin-right: 4px;
  transition: background-color 0.2s, opacity 0.2s;
  height: 32px;
  padding: 0 16px;
  line-height: 1;
  opacity: 1;
  outline: 0;
  justify-content: center;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  align-items: center;

  background: ${({ theme }) => (theme.isDark ? 'rgba(255, 255, 255, 0.1)' : '#FFFFFF')};
  color: ${({ theme }) => (theme.isDark ? '#D6DEEB' : '#7D9CD1')};

  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: ${({ theme }) => (theme.isDark ? '0' : '1px solid #C9DCFB')};
`
const GasSettings = () => {
  const { t } = useTranslation()
  const [gasPrice, setGasPrice] = useGasPriceManager()

  return (
    <Flex flexDirection="column">
      <Flex mb="12px" alignItems="center">
        <Text>{t('Default Transaction Speed (GWEI)')}</Text>
        <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees',
          )}
          placement="top-start"
          ml="4px"
        />
      </Flex>
      <Flex flexWrap="wrap">
        <StyledButton
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.default)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.default ? 'primary' : 'tertiary'}
        >
          {t('Standard (%gasPrice%)', { gasPrice: GAS_PRICE.default })}
        </StyledButton>
        <StyledButton
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.fast)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.fast ? 'primary' : 'tertiary'}
        >
          {t('Fast (%gasPrice%)', { gasPrice: GAS_PRICE.fast })}
        </StyledButton>
        <StyledButton
          mr="4px"
          mt="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.instant)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.instant ? 'primary' : 'tertiary'}
        >
          {t('Instant (%gasPrice%)', { gasPrice: GAS_PRICE.instant })}
        </StyledButton>
      </Flex>
    </Flex>
  )
}

export default GasSettings
