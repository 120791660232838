import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import axios from "axios";
import { useSattWallet } from "../../../../src/state/satt/hooks";
import { sattTokenLocalStorageKey } from "../widgets/WalletModal/config";
var useConnectSatt = function() {
    var addSattUserAddress = useSattWallet().addSattUserAddress;
    var sattLogin = function() {
        var _ref = _async_to_generator(function(username, password) {
            var sattUser, data, _data, address, keyStore, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        sattUser = {
                            username: username,
                            password: password
                        };
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            3,
                            ,
                            4
                        ]);
                        return [
                            4,
                            axios.post("https://api-preprod2.satt-token.com/auth/satt-connect", sattUser)
                        ];
                    case 2:
                        data = _state.sent().data;
                        _data = data.data, address = _data.address, keyStore = _data.keystore;
                        if (address && keyStore) {
                            localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem(sattTokenLocalStorageKey, data.data.access_token);
                            addSattUserAddress({
                                address: address,
                                keyStore: keyStore
                            });
                            return [
                                2,
                                true
                            ];
                        }
                        return [
                            2,
                            false
                        ];
                    case 3:
                        error = _state.sent();
                        return [
                            2,
                            error
                        ];
                    case 4:
                        return [
                            2
                        ];
                }
            });
        });
        return function sattLogin(username, password) {
            return _ref.apply(this, arguments);
        };
    }();
    var getSattWalletAddress = function() {
        var _ref = _async_to_generator(function(token) {
            var option, data, keyStore, address;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        option = {
                            headers: {
                                Authorization: "Bearer ".concat(token)
                            }
                        };
                        return [
                            4,
                            axios.post("https://api-preprod2.satt-token.com/wallet/exportWalletInfos", {}, option)
                        ];
                    case 1:
                        data = _state.sent().data;
                        keyStore = data.keystore, address = data.address;
                        addSattUserAddress({
                            address: address,
                            keyStore: keyStore
                        });
                        return [
                            2
                        ];
                }
            });
        });
        return function getSattWalletAddress(token) {
            return _ref.apply(this, arguments);
        };
    }();
    return {
        sattLogin: sattLogin,
        getSattWalletAddress: getSattWalletAddress
    };
};
export default useConnectSatt;
