import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'MoonBiz',
  description:
    'The most popular AMM on BSC by user count! Earn RP1 through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Moon-Biz), NFTs, and more, on a platform you can trust.',
  image: '',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Moon-Biz')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('Moon-Biz')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('Moon-Biz')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('Moon-Biz')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('Moon-Biz')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('Moon-Biz')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('Moon-Biz')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('Moon-Biz')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('Moon-Biz')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Moon-Biz')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('Moon-Biz')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('Moon-Biz')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('Moon-Biz')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('Moon-Biz')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('Moon-Biz')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('Moon-Biz')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('Moon-Biz')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('Moon-Biz')}`,
      }
    // case '/info':
    //  return {
    //    title: `${t('Overview')} | ${t('PancakeSwap Info & Analytics')}`,
    //    description: 'View statistics for Pancakeswap exchanges.',
    //  }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('Moon-Biz Info & Analytics')}`,
        description: 'View statistics for Moon-Biz exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('Moon-Biz Info & Analytics')}`,
        description: 'View statistics for Moon-Biz exchanges.',
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('Moon-Biz')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('Moon-Biz')}`,
      }
    case '/nfts/activity':
      return {
        title: `${t('Activity')} | ${t('Moon-Biz')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Profile')} | ${t('Moon-Biz')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Pancake Squad')} | ${t('Moon-Biz')}`,
      }
    default:
      return null
  }
}
